import React, { useState, useEffect } from "react";
import CategoryList from "./CategoryList";
import RecentPosts from "./RecentPosts";
import AppLoader from "./AppLoader/index";
import axios from "axios";
import Footer from "./Footer";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINT } from "../Api";
import Header from "./Header";

const Posts = () => {
  const [post, setPost] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [featureImage, setFeatureImage] = useState("");
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { postName } = useParams();
  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_ENDPOINT}/posts?_embed&&slug=${postName}`
        );
        if (Array.isArray(response.data) && response.data.length > 0) {
          setPost(response.data[0]);
          setAuthor(response.data[0]._embedded.author["0"].name);
          fetchCategoryNames(response.data[0]._embedded["wp:term"][0]);
          setFeatureImage(
            response.data[0]._embedded["wp:featuredmedia"]["0"]["source_url"]
          );
          setLoading(false);
        } else {
          console.error("Post not found.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching post:", error);
        setLoading(false);
      }
    };
    fetchPost();
  }, [postName]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_ENDPOINT}/posts`);
        setRecentPosts(response.data);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  const handleCategoryClickMain = () => {
    // Update the selected category and trigger a fetch for its posts
    navigate(`/blog`);
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const fetchCategoryNames = async (category) => {
    const categorynames = category
      .map((categoryGroup) => categoryGroup.name)
      .flat()
      .join(", ");
    setCategoryNames(categorynames);
  };

  return (
    <>
      <Header />
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">{post && post.title.rendered}</h1>
          </div>
        </div>
      </div>
      <div className="rs-inner-blog rs-blog style2 pt-60 pb-60 md-pt-60 md-pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="widget-area">
                <CategoryList onCategoryClick={handleCategoryClickMain} />
                <RecentPosts recentPosts={recentPosts} />
              </div>
            </div>

            <div className="col-lg-8 pr-35 md-pr-15 md-mt-50">
              <div className="row">
                <div className="col-lg-12">
                  {loading ? (
                    <div className="blog-details">
                      <AppLoader />
                    </div>
                  ) : (
                    post && (
                      <div className="blog-details">
                        <div className="bs-img mb-35">
                          <img
                            src={
                              featureImage
                                ? featureImage
                                : "/assets/images/blog/inner/style1/benefits-of-e-commerce.webp"
                            }
                            alt=""
                          />
                        </div>

                        <div className="blog-full">
                          <ul className="single-post-meta">
                            <li>
                              <span className="p-date">
                                <i className="fa fa-calendar-check-o"></i>{" "}
                                {formatDate(post.date)}
                              </span>
                            </li>
                            <li>
                              <span className="p-date">
                                {" "}
                                <i className="fa fa-user-o"></i> {author}{" "}
                              </span>
                            </li>
                            <li className="Post-cate">
                              <div className="tag-line">
                                <i className="fa fa-book"></i>
                                <a>{categoryNames}</a>
                              </div>
                            </li>
                          </ul>
                          <h2>{post.title.rendered}</h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: post.content.rendered,
                            }}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Posts;
