import React, { useEffect, useState } from "react";
import { API_ENDPOINT } from "../Api";
import ScrollToTopLink from "./ScrollToTopLink";

const RecentPosts = ({ recentPosts }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const [featuredImages, setFeaturedImages] = useState([]);

  useEffect(() => {
    const fetchFeaturedImages = async () => {
      const imagePromises = recentPosts.map(
        (post) =>
          fetch(`${API_ENDPOINT}/media/${post.featured_media}`)
            .then((response) => response.json())
            .then((data) => data.source_url) // Assuming source_url holds the image URL
      );

      const images = await Promise.all(imagePromises);
      setFeaturedImages(images);
    };

    fetchFeaturedImages();
  }, [recentPosts]);
  return (
    <div className="recent-posts mb-50">
      <div className="widget-title">
        <h3 className="title">Recent posts</h3>
      </div>
      {recentPosts.slice(0, 3).map((post, index) => (
        <div className="recent-post-widget no-border" key={post.id}>
          <div className="post-img">
            <ScrollToTopLink to={`/blog/${post.slug}`} aria-hidden="true">
              <img
                src={
                  featuredImages[index]
                    ? featuredImages[index]
                    : "/assets/images/blog/inner/style1/benefits-of-e-commerce.webp"
                }
                alt=""
              />
            </ScrollToTopLink>
          </div>
          <div className="post-desc">
            <ScrollToTopLink to={`/blog/${post.slug}`} aria-hidden="true">
              {post.title.rendered}
            </ScrollToTopLink>
            <span className="date-post">
              <i className="fa fa-calendar"></i> {formatDate(post.date)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecentPosts;
