import Fparticles from "./Fparticles";
import ScrollToTopLink from "./ScrollToTopLink";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
    

      <footer id="rs-footer" className="rs-footer style1 footer-home5-style">
      <Fparticles />
        <div className="footer-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <div className="footer-logo mb-40">
                  <ScrollToTopLink to="/">
                    <img src="/assets/images/hiltonlogo.webp" alt="" />
                  </ScrollToTopLink>
                </div>
                <div className="textwidget white-color pb-30">
                  <p>
                    Hilton technologies, a leading e-Commerce website
                    development company in India, offers customized solutions
                    that meet the unique needs of various business types,
                    ensuring client satisfaction.
                  </p>
                </div>
                <ul className="footer-social md-mb-30">
                  <li>
                    <a
                      href="https://www.facebook.com/hiltonsoftwarecompany/"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-facebook"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Hilton_global" target="_blank">
                      <span>
                        <i className="fa fa-twitter"></i>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://in.linkedin.com/company/hilton-global"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-linkedin"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hiltonsoftwaretechnologies/?hl=en"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-instagram"></i>
                      </span>
                    </a>
                  </li>
                </ul>
                
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <h3 className="footer-title">Our services</h3>
                <ul className="site-map">
                  <li>
                    <ScrollToTopLink to="/ai-ml">
                      AI & ML
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/bi-analytics">
                     Data & Analytics
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/erp-solutions">
                      ERP Solutions
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/fullstack-development">
                      Full Stack Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/e-commerce-development">
                      e-Commerce Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/mobile-app-development">
                      Mobile Apps Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/web-app-development">
                      Web Application Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/hire-a-developer">
                      Hire a Developer
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/technical-support">
                      Technical Support
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/digital-marketing">
                      Digital Marketing
                    </ScrollToTopLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <h3 className="footer-title">Contact info</h3>
                <ul className="address-widget">
                  <li>
                    <i className="flaticon-location"></i>
                    <div className="desc">
                      INDIA: 8-3-191/17, 2/A, 3rd Floor, Vengala Rao Nagar, S.R
                      Nagar, Hyderabad-38.
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-location"></i>
                    <div className="desc">
                      USA: 3340 walnut avenue, suite #296, Fremont CA - 94538.
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-call"></i>
                    <div className="desc">
                      <a href="tel:(+91)7993606894">(+91)7993606894</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <div className="desc">
                      <a href="mailto:info@hiltonglobal.co">
                        info@hiltonglobal.co
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.370941326421!2d78.44029071487714!3d17.44195108804591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb7d99859ef3bbc!2sHilton%20Software%20Technologies%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1575441617276!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  allowFullScreen=""
                ></iframe>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-30">
                <div className="row accredited">
                   <div className="sec-title4 mb-1">
                    <h3 className="title text-white">We are accredited by</h3>
                    <p className="text-white">we have been attained a specific qualification including</p>
                    </div>
                   <div className="rs-counter hover-box">
                      <div className="counter-icon">
                        <img
                          src="/assets/images/clients/magento_certified.webp"
                          alt="Images" style={{height: '90px'}}
                        />
                      <img
                          src="/assets/images/zend-php-engineer.webp"
                          alt="Images" style={{height: '70px'}}
                        />
                      </div>
                    </div>
                </div>
              
              
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container-fluid">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-10 text-lg-end text-center order-last">
                <ul className="copy-right-menu">
                  <li>
                    <ScrollToTopLink to="/privacy-policy">
                      Privacy policy
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/terms-conditions">
                      Terms & conditions
                    </ScrollToTopLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="copyright text-lg-start text-center ">
                  <p>
                    © {currentYear}{" "}
                    Hilton Software Technologies
                    . All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="modal fade search-modal" id="searchModal" tabIndex="-1">
        <button type="button" className="close" data-bs-dismiss="modal">
          <span className="flaticon-cross"></span>
        </button>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="search-block clearfix">
              <form>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Search Here..."
                    type="text"
                  />
                  <button type="submit" value="Search">
                    <i className="flaticon-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
