import React from 'react';

const TabContentComponentBiApp = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/power-bi.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                                Power BI
                            </h2>
                            <p className="margin-0">
                            At Hilton, we utilize Power BI to provide tailored data analytics solutions that simplify decision-making. Power BI’s advanced visualization capabilities allow us to build dynamic dashboards and reports, giving you real-time insights into critical business metrics. By integrating Power BI with multiple data sources, we enable seamless data analysis, helping you uncover trends and opportunities. Our expertise ensures you get actionable insights that drive informed, strategic decisions. With Power BI, we help businesses enhance performance and achieve measurable growth through data-driven strategies.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/mircosoft-fabric.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Microsoft Fabric
                        </h2>
                        <p className="margin-0">
                        At Hilton, we utilize Microsoft Fabric to streamline and enhance your data management and analytics processes. This all-in-one data platform integrates data engineering, integration, and advanced analytics into a cohesive solution, simplifying the way businesses handle their data. Microsoft Fabric provides powerful tools for real-time data analysis, AI-driven insights, and seamless data governance. By leveraging its scalable architecture and advanced features, we help organizations transform their data into actionable insights, driving smarter decision-making and operational efficiency.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/azure-data.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Azure Data Factory
                        </h2>
                        <p className="margin-0">
                        At [Your Company Name], we harness Azure Data Factory to design and automate data pipelines, enabling seamless integration of data from various sources, whether on-premises or in the cloud. We use Azure Data Factory’s powerful ETL (Extract, Transform, Load) capabilities to streamline data workflows, ensuring timely and accurate data processing. This service supports real-time data updates, enhances data quality, and simplifies complex data operations. With Azure Data Factory, we help optimize your data infrastructure, improve overall data handling, and empower your business with actionable insights for better decision-making.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
           
            }
        </div>
    );
};

export default TabContentComponentBiApp;
