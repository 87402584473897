import ScrollToTopLink from "./ScrollToTopLink";
import { Tooltip } from "@mui/material";

const BlogItem = ({ title, date, content, featured_media, slug }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const truncatedTitle =
    title.length > 50 ? title.substring(0, 50) + "..." : title;
  return (
    <div className="blog-item">
      <div className="blog-img">
        <img
          src={
            featured_media
              ? featured_media
              : "/assets/images/blog/inner/style1/benefits-of-e-commerce.webp"
          }
          alt=""
        />
      </div>
      <div className="blog-content">
        <h3 className="blog-title">
          <ScrollToTopLink to={`/blog/${slug}`} aria-hidden="true">
            <Tooltip title={title}>{truncatedTitle}</Tooltip>
          </ScrollToTopLink>
        </h3>
        <div className="blog-meta">
          <ul className="btm-cate">
            <li>
              <div className="blog-date">
                <i className="fa fa-calendar-check-o"></i> {formatDate(date)}
              </div>
            </li>
          </ul>
        </div>
        <div className="blog-desc">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div className="blog-button">
          <ScrollToTopLink
            className="blog-btn"
            to={`/blog/${slug}`}
            aria-hidden="true"
          >
            Continue reading
          </ScrollToTopLink>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
