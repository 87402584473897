import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlogList from "./components/BlogList";
import "font-awesome/css/font-awesome.css";
import Post from "./components/Post";
import About from "./components/Pages/About";
import Home from "./components/Pages/Home";
import HireADeveloper from "./components/Pages/Hire-A-Developer";
import Contact from "./components/Pages/Contact";
import BIAnalytics from "./components/Pages/BIAnalytics";
import ERPSolutions from "./components/Pages/ERPSolutions";
import Careers from "./components/Pages/Careers";
import DigitalMarketing from "./components/Pages/DigitalMarketing";
import TechnicalSupport from "./components/Pages/TechnicalSupport";
import FullStackDevelopment from "./components/Pages/FullStackDevelopment";
import EcommerceDevelopment from "./components/Pages/EcommerceDevelopment";
import MobileAppDevelopment from "./components/Pages/MobileAppDevelopment";
import WebAppDevelopment from "./components/Pages/WebAppDevelopment";
import Service from "./components/Pages/Service";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import TermAndConditions from "./components/Pages/TermAndConditions";
import "./responsive.css";
import AiMl from "./components/Pages/AiMl";

function App() {
  return (
    <div className="main-content">
      <Router>
        <Routes>
          <Route path="/blog" element={<BlogList />} />
          <Route index exact path="/" element={<Home />} />
          <Route path="/ai-ml" element={<AiMl />} />
          <Route path="/bi-analytics" element={<BIAnalytics />} />
          <Route path="/erp-solutions" element={<ERPSolutions />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Service />} />
          <Route path="/hire-a-developer" element={<HireADeveloper />} />
          <Route path="/technical-support" element={<TechnicalSupport />} />
          <Route
            path="/fullstack-development"
            element={<FullStackDevelopment />}
          />
          <Route
            path="/e-commerce-development"
            element={<EcommerceDevelopment />}
          />
          <Route
            path="/mobile-app-development"
            element={<MobileAppDevelopment />}
          />
          <Route path="/web-app-development" element={<WebAppDevelopment />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/blog/:postName" element={<Post />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermAndConditions />} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
