import React from "react";

const Pagination = ({ postsPerPage, totalPosts, currentPage, paginate }) => {
  const pageNumbers = Math.ceil(totalPosts / postsPerPage);

  // Hide pagination if there's only one page
  if (pageNumbers === 1) return null;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="col-lg-12">
      <div className="pagination-area">
        <div className="nav-link">
          {/* Display Previous button if not on the first page */}
          {currentPage !== 1 && (
            <a
              onClick={() => {
                paginate(currentPage - 1);
                scrollToTop();
              }}
              className="page-number pointer"
            >
              Previous
            </a>
          )}

          {/* Display page numbers */}
          {Array.from({ length: pageNumbers }, (_, index) => (
            <a
              key={index + 1}
              onClick={() => {
                paginate(index + 1);
                scrollToTop();
              }}
              className={`page-number pointer ${
                currentPage === index + 1 ? "white-color" : ""
              }`}
            >
              {index + 1}
            </a>
          ))}

          {/* Display Next button if not on the last page */}
          {currentPage !== pageNumbers && (
            <a
              onClick={() => {
                paginate(currentPage + 1);
                scrollToTop();
              }}
              className="page-number pointer border-none"
            >
              Next
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
