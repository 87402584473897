import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import React, { useState } from "react";
import ClientSection from "../ClientSection";
import { clientslider } from "../CarosuelsSettings";
import MetaTag from "../MetaTag";
import TabsComponentTSApp from "./TabsComponentTSApp";
import TabContentComponentTSApp from "./TabContentComponentTSApp";

const TechnicalSupport = () => {
  const MetaTitle = "Trusted Technical Support Services | Hiltonglobal";
  const MetaDescription =
    "Get top-notch technical support services for your website. Our experienced team is dedicated to resolving any technical issues, providing troubleshooting assistance, and ensuring your website runs smoothly. Trust us for reliable solutions and peace of mind. Contact us today!";
  const MetaKeywords =
    "it help desk, it service desk, managed it support services, troubleshooting support, technical production support, installation support";
  const [activeKey, setActiveKey] = useState('tab1');
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
      />
      <Header />
      <BreadCrums
        name={"tech-support"}
        tittle={"Technical support services"}
        watermark={"Technical support"}
      />
      <div id="" className="fulfill-ecommerce pt-60 pb-30 md-pt-60 md-pb-30">
        <div className="container-fluid custom">
          <div className="sec-title text-center mb-30 md-mb-30">
            <h2 className="title title2">
              Technical support services for small, medium and enterprises
            </h2>
            <div className="bar-main">
                  <div className="bar bar-big"></div>
             </div>
          </div>

          <div className="row y-middle text-center">
            <div className="col-md-1"></div>

            <div className="col-lg-10 col-md-10">
              <p className="services-txt">
              Maintain peak performance with our top-tier Technical Support Services, designed to address all your software needs. Our expert team is available 24/7, ensuring that technical issues are swiftly resolved to minimize any disruption to your operations. We offer comprehensive support, including troubleshooting, system maintenance, and timely software updates to keep your systems secure and running smoothly. Our proactive approach helps identify and address potential issues before they impact your business.
              </p>
              <p className="services-txt">With personalized support tailored to your unique requirements, we ensure that every aspect of your software environment is managed effectively. Our focus on efficiency and user satisfaction means you can rely on us to keep your technology infrastructure in top shape. Stay ahead with regular performance updates and insights, allowing you to make informed decisions. Trust us to handle your technical challenges, so you can concentrate on driving innovation and achieving your business goals.</p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="major-platforms"
        className="major-platforms flipk pt-30 md-pt-20"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
             Technical Support Services
            </h2>
            <p className="margin-0">
              Come and choose your specific business requirements from our pool
              of expert solutions.
            </p>
            <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div>
          </div>
        </div>
      </div>
      <div
        id="ecom-platforms"
        className="ecom-platforms flipk"
      >
          <div className="container-fluid mt-4">
            <TabsComponentTSApp activeKey={activeKey} onSelect={setActiveKey} />
          </div>
          <div>
          <TabContentComponentTSApp activeKey={activeKey} />
          </div>
      </div>
      

      <div
        id="operational-ecommerce"
        className="rs-single-shop vertical operational-ecommerce  pt-20 pb-60 md-pt-60 sm-pb-68-0 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-30 md-mb-30">
            <h2 className="title title2">Tech support services includes</h2>

            <p className="margin-0">
              We offer peerless services to drive your business growth
            </p>
          </div>

          <div className="tab-area desktop">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="IT-helpdesk-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#IT-helpdesk"
                  type="button"
                  role="tab"
                  aria-controls="IT-helpdesk"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/it-help-desk.webp" /> IT Help
                  Desk
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Software-Helpdesk-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Software-Helpdesk"
                  type="button"
                  role="tab"
                  aria-controls="Software-Helpdesk"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/software-help-desk.webp" />{" "}
                  Software Help Desk
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Managed-ITsupport-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Managed-ITsupport"
                  type="button"
                  role="tab"
                  aria-controls="Managed-ITsupport"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/managed-it-support.webp" />{" "}
                  Managed IT Support
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Installation-support-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Installation-support"
                  type="button"
                  role="tab"
                  aria-controls="Installation-support"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/installation-support.webp" />{" "}
                  Installation Support
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Troubleshooting-support-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Troubleshooting-support"
                  type="button"
                  role="tab"
                  aria-controls="Troubleshooting-support"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/troubleshooting-support.webp" />{" "}
                  Troubleshooting Support
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Technical-productionsupport-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Technical-productionsupport"
                  type="button"
                  role="tab"
                  aria-controls="Technical-productionsupport"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/technical-production-support.webp" />{" "}
                  Technical Production Support
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Server-database-support-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Server-database-support"
                  type="button"
                  role="tab"
                  aria-controls="Server-database-support"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/database-support.webp" /> Server
                  and Database Support
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="IT-helpdesk"
                role="tabpanel"
                aria-labelledby="IT-helpdesk-tab"
              >
                <h3>IT help desk</h3>

                <p className="">
                  IT help desk software functions as a centralized point of
                  contact for users seeking technical assistance. It empowers
                  employees and IT team members to resolve issues, track
                  progress, and receive guidance on products, services, or
                  processes. The IT help desk serves as the primary solution
                  when encountering technical problems with software
                  applications or devices.
                </p>
              </div>

              <div
                className="tab-pane fade show"
                id="Software-Helpdesk"
                role="tabpanel"
                aria-labelledby="Software-Helpdesk-tab"
              >
                <h3>Software help desk</h3>

                <p className="">
                  A robust software helpdesk is an essential component of
                  delivering exceptional customer support. Without it, customer
                  support efforts are incomplete and largely ineffective. By
                  leveraging a suitable helpdesk ticketing system, businesses
                  can centralize customer conversations from multiple channels,
                  monitor all inquiries, and engage with customers on their
                  preferred platform, streamlining the resolution of support
                  queries.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Managed-ITsupport"
                role="tabpanel"
                aria-labelledby="Managed-ITsupport-tab"
              >
                <h3>Managed IT support</h3>

                <p className="">
                  Managed IT support refers to the delegation of specific
                  responsibilities to a third-party entity within the realm of
                  business IT services. By adopting the managed services model,
                  companies can outsource routine tasks to specialists, leading
                  to cost savings, improved service quality, and increased
                  capacity for internal teams to focus on tasks that align with
                  their unique business requirements.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Installation-support"
                role="tabpanel"
                aria-labelledby="Installation-support-tab"
              >
                <h3>Installation support</h3>

                <p className="">
                  Our installation support service offers valuable assistance in
                  the installation of your purchased products, delivered by our
                  knowledgeable technical experts. To ensure a smooth process,
                  it is crucial for you to identify your workstations and
                  servers and arrange remote access for our team. Once granted
                  access, our skilled technical experts utilize advanced remote
                  support tools to promptly and efficiently provide the
                  assistance you need.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Troubleshooting-support"
                role="tabpanel"
                aria-labelledby="Troubleshooting-support-tab"
              >
                <h3>Troubleshooting support</h3>

                <p className="">
                  Troubleshooting is a systematic approach employed to resolve
                  issues and anomalies. Its primary goal is to identify the
                  underlying cause of unexpected behavior and devise appropriate
                  solutions to rectify the problem. The initial phase of
                  troubleshooting entails providing a thorough and detailed
                  description of the issue at hand.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Technical-productionsupport"
                role="tabpanel"
                aria-labelledby="Technical-productionsupport-tab"
              >
                <h3>Technical production support</h3>

                <p className="">
                  Technical production support is a collaborative endeavor
                  involving technical support teams and key business
                  stakeholders, particularly traders, to effectively address any
                  technical challenges pertaining to systems and applications.
                  In a trading environment, traders typically initiate support
                  requests, commonly known as 'tickets,' through channels like
                  email or telephone, and closely collaborate with support teams
                  to expedite issue resolution.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Server-database-support"
                role="tabpanel"
                aria-labelledby="Server-database-support-tab"
              >
                <h3>Server and database support</h3>

                <p className="">
                  At our company, customer satisfaction is our top priority as
                  we offer comprehensive and premium-quality database services.
                  We go beyond standard maintenance and focus on optimizing
                  efficiency while reducing costs. Our commitment to
                  adaptability and personalized service has resulted in
                  exceptional customer satisfaction and a high rate of client
                  retention.
                </p>
              </div>
            </div>

            <div className="clearboth"></div>
          </div>
          {/* <!-- .tab-area --> */}
          <div className="rs-faq mobile">
            <div className="faq-content">
              <div id="accordion" className="accordion">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/it-help-desk.webp" />
                          <span>IT Help Desk</span>
                        </a>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>IT help desk</h3>
                          <p className="">
                            IT help desk software functions as a centralized
                            point of contact for users seeking technical
                            assistance. It empowers employees and IT team
                            members to resolve issues, track progress, and
                            receive guidance on products, services, or
                            processes. The IT help desk serves as the primary
                            solution when encountering technical problems with
                            software applications or devices.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/software-help-desk.webp" />
                          <span> Software Help Desk</span>
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Software help desk</h3>
                          <p className="">
                            A robust software helpdesk is an essential component
                            of delivering exceptional customer support. Without
                            it, customer support efforts are incomplete and
                            largely ineffective. By leveraging a suitable
                            helpdesk ticketing system, businesses can centralize
                            customer conversations from multiple channels,
                            monitor all inquiries, and engage with customers on
                            their preferred platform, streamlining the
                            resolution of support queries.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/managed-it-support.webp" />
                          <span>Managed IT Support</span>
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Managed IT support</h3>
                          <p className="">
                            Managed IT support refers to the delegation of
                            specific responsibilities to a third-party entity
                            within the realm of business IT services. By
                            adopting the managed services model, companies can
                            outsource routine tasks to specialists, leading to
                            cost savings, improved service quality, and
                            increased capacity for internal teams to focus on
                            tasks that align with their unique business
                            requirements.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/installation-support.webp" />
                          <span> Installation Support</span>
                        </a>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Installation support</h3>
                          <p className="">
                            Our installation support service offers valuable
                            assistance in the installation of your purchased
                            products, delivered by our knowledgeable technical
                            experts. To ensure a smooth process, it is crucial
                            for you to identify your workstations and servers
                            and arrange remote access for our team. Once granted
                            access, our skilled technical experts utilize
                            advanced remote support tools to promptly and
                            efficiently provide the assistance you need.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/troubleshooting-support.webp" />
                          <span>Troubleshooting Support</span>
                        </a>
                      </div>
                      <div
                        id="collapseFive"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Troubleshooting support</h3>
                          <p className="">
                            Troubleshooting is a systematic approach employed to
                            resolve issues and anomalies. Its primary goal is to
                            identify the underlying cause of unexpected behavior
                            and devise appropriate solutions to rectify the
                            problem. The initial phase of troubleshooting
                            entails providing a thorough and detailed
                            description of the issue at hand.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/technical-production-support.webp" />
                          <span>Technical Production Support</span>
                        </a>
                      </div>
                      <div
                        id="collapseSix"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Technical production support</h3>
                          <p className="">
                            Technical production support is a collaborative
                            endeavor involving technical support teams and key
                            business stakeholders, particularly traders, to
                            effectively address any technical challenges
                            pertaining to systems and applications. In a trading
                            environment, traders typically initiate support
                            requests, commonly known as 'tickets,' through
                            channels like email or telephone, and closely
                            collaborate with support teams to expedite issue
                            resolution.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/database-support.webp" />
                          <span>Server and Database Support</span>
                        </a>
                      </div>
                      <div
                        id="collapseSeven"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Server and database support</h3>
                          <p className="">
                            At our company, customer satisfaction is our top
                            priority as we offer comprehensive and
                            premium-quality database services. We go beyond
                            standard maintenance and focus on optimizing
                            efficiency while reducing costs. Our commitment to
                            adaptability and personalized service has resulted
                            in exceptional customer satisfaction and a high rate
                            of client retention.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services main-home advantages services-style1 home-4-style bg7 pt-60 pb-60 md-pt-60 md-pb-60">
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-65 md-mb-45">
            <h2 className="title pb-25">Hilton’s Advantages</h2>
            <div className="bar-main">
                  <div className="bar bar-big"></div>
             </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 mb-40 sm-mb-20">
            <div className="card">
            <div className="card-body">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/contact.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Single point of contact</a>
                  </h2>

                  <p className="services-txt">
                    We consolidate information for reliability and optimal
                    decision-making, ensuring top-notch services.
                  </p>

                  <div className="serial-number">01</div>
                </div>
              </div>
              </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-40 sm-mb-20">
            <div className="card">
            <div className="card-body">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/experts.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Experienced team</a>
                  </h2>

                  <p className="services-txt">
                    Our highly skilled team creatively solves complex tasks and
                    collaborates for successful project delivery.
                  </p>

                  <div className="serial-number">02</div>
                </div>
              </div>
              </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-40 sm-mb-20">
            <div className="card">
            <div className="card-body">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/domain.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Domain experts</a>
                  </h2>

                  <p className="services-txt">
                    We assign domain experts for exceptional results across
                    engineering and non-engineering projects.
                  </p>

                  <div className="serial-number">03</div>
                </div>
              </div>
              </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 md-mb-40 sm-mb-20">
            <div className="card">
            <div className="card-body">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/security.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Data security</a>
                  </h2>

                  <p className="services-txt">
                    As an ISO 27001 certified organization, we prioritize
                    rigorous data protection through advanced software,
                    machines, and skilled professionals.
                  </p>

                  <div className="serial-number">04</div>
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="process-steps"
        className="mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60"
      >
        <div className="container-fluid">
          <div className="sec-title text-center mb-60">
            <h2 className="title title2 pb-20">
            Technical Support Process
            </h2>
            <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="process-steps">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Pre-Support Preparation" title="Pre-Support Preparation" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Pre-Support Preparation</h3>
                      <p>Evaluate the support needs of the business and plan resources and processes accordingly.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Incident Reporting and Tracking" title="Incident Reporting and Tracking" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Incident Reporting and Tracking</h3>
                      <p>Log and categorize support requests for efficient tracking and resolution.</p>
                    </div>
               </div>
               <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Diagnosis and Resolution" title="Diagnosis and Resolution" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Diagnosis and Resolution</h3>
                      <p>Assess and diagnose the reported problem. And apply fixes or workarounds, and verify that the issue is resolved effectively.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Post Resolution and Follow-up" title="Post Resolution and Follow-up" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Post Resolution and Follow-up</h3>
                      <p>Update records and knowledge base with details about the issue and its resolution.</p>
                    </div>
               </div>
               <div className="process-item">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Maintenance and Prevention" title="Maintenance and Prevention" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Maintenance and Prevention</h3>
                      <p>Perform regular updates and system checks to ensure ongoing performance.</p>
                    </div>
               </div>
               </div>
            </div>

           

          </div>
        </div>
      </div>
      <div className="rs-estimate hire-technical relative pt-60 pb-60 md-pt-60 md-mt-0 md-pb-60 light-yellow-bg">
        <div className="container">
        <div className="sec-title3 text-center mb-65 md-mb-45">
            <h2 className="title pb-25">Hire Technical Support Staff was Never too Easy</h2>
            <div className="bar-main">
                  <div className="bar bar-big"></div>
             </div>
             <p className="mb-0">At Hilton, simplicity is our priority.</p>
          </div>
          <div className="row rs-vertical-middle">
            <div className="col-lg-6">
            <div className="image-part">
                <img src="/assets/images/support.webp" alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="dt-sc-icon-box type5 custom-style " data-delay="0">
                <div className="icon-wrapp"></div>
                <div className="content">
                  <h5>Requirement Request</h5>
                  <p>Send a detailed description of your requirement.</p>
                </div>
              </div>
              <div className="dt-sc-icon-box type5 custom-style " data-delay="0">
                <div className="icon-wrapp"></div>
                <div className="content">
                  <h5>Select from Curated CVs</h5>
                  <p>Choose from our curated CVs for interviews.</p>
                </div>
              </div>
              <div className="dt-sc-icon-box type5 custom-style " data-delay="0">
                <div className="icon-wrapp"></div>
                <div className="content">
                  <h5>Conduct Video Interviews</h5>
                  <p>Conduct video conference interviews and select the best-fit candidate.</p>
                </div>
              </div>
              <div className="dt-sc-icon-box type5 custom-style " data-delay="0">
                <div className="icon-wrapp"></div>
                <div className="content">
                  <h5>Hire and Start Collaboration</h5>
                  <p>Hire your chosen candidate and commence your collaboration promptly.</p>
                </div>
              </div>

              <ul className="estimate-info hidden">
                <li>
                  <p className="margin-0 title">
                    <i className="fa fa-file-text-o"></i> Send a detailed
                    description of your requirement.
                  </p>
                </li>

                <li>
                  <p className="margin-0 title">
                    <i className="fa fa-check"></i> Choose from our curated CVs
                    for interviews.
                  </p>
                </li>

                <li>
                  <p className="margin-0 title">
                    <i className="fa fa-user-o"></i> Conduct video conference
                    interviews and select the best-fit candidate.
                  </p>
                </li>

                <li>
                  <p className="margin-0 title">
                    <i className="fa fa-thumbs-o-up"></i> Hire your chosen
                    candidate and commence your collaboration promptly.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <img
            className="pattern-img"
            src="/assets/images/pattern/pattern4.webp"
            alt=""
          />
        </div>
      </div>

      {/* <!-- Partner Section Start --> */}
      <div className="rs-patter-section  pt-60 pb-60">
        <div className="container custom">
          <ClientSection settings={clientslider} />
        </div>
      </div>

      {/* <!-- Partner Section End --> */}

      <Footer />
    </>
  );
};

export default TechnicalSupport;
