import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import React from "react";
import Slider from "react-slick";
import { clientslider } from "../CarosuelsSettings";
import RecentBlog from "../RecentBlog";
import MetaTag from "../MetaTag";
import ScrollToTopLink from "../ScrollToTopLink";

const HireADeveloper = () => {
  const MetaTitle = "Our Services | Hiltonglobal";
  const MetaDescription =
    "Explore our comprehensive range of services. We provide a wide array of solutions to address your specific needs effectively.";
  const MetaKeywords =
    "web development, digital marketing, cloud solutions, IT consulting, software development, mobile app development, SEO services, e-commerce solutions, data analytics, technology consulting";

  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
      />
      <Header />
      <BreadCrums
        name={"services"}
        tittle={"We build the software you need"}
        watermark={"Services"}
      />
      <div
        id=""
        className="rs-services style4 pt-60 pb-60 md-pt-60 md-pb-60 hidden"
        // style="background-color:#fbfbfb;"
      >
        <div className="container-fluid">
          <div className="sec-title text-center">
            <h2 className="title title2 pb-20">What we are</h2>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-30 text-center">
              <p className="margin-0 ft16">
                Hilton offers a wide range of services are designed to help
                businesses operate efficiently and securely in today's
                technology-driven world.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section
        className="space-top pt-60 pb-60 md-pt-60 md-pb-60 services-main"
        // style="background-color:#f6f6f6;"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="sec-title text-center">
                <h2 className="title title2">Our Services</h2>
                <div class="bar-main">
                  <div class="bar bar-big"></div>
                </div>
              </div>
              <div className="row">
              <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center ai-ml">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/creative.png"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/ai-ml">
                        AI & ML
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                    Artificial Intelligence (AI) is reshaping the software development landscape by introducing smart, adaptive features into your applications.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/ai-ml"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center data-analytics">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/dashboard.png"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/bi-analytics">
                        Data & Analytics
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                    At Hilton, our Data and Analytics services help businesses turn complex data into actionable insights. We offer comprehensive solutions for data collection, storage, analysis, and visualization, ensuring your data is transformed into a valuable asset for decision-making.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/bi-analytics"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center erp-solutions">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/erp-system.png"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/erp-solutions">
                        ERP Solutions
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                    Our software development company delivers cutting-edge ERP (Enterprise Resource Planning) solutions that transform how businesses manage their core operations. 
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/erp-solutions"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center fullstack">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/fullstack.webp"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/fullstack-development">
                        Full Stack Development
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                      Hilton specializes in full stack development and custom
                      web API solutions, delivering tailored solutions to meet
                      your specific needs.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/fullstack-development"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center ecommerce">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/ecommerce.webp"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/e-commerce-development">
                        e-Commerce Development
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                      Hilton excels in custom e-commerce development, offering
                      personalized solutions to accelerate projects and provide
                      expert consultation for successful online ventures.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/e-commerce-development"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center mobileapp">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/mobileapp.webp"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/mobile-app-development">
                        Mobile Application Development
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                      Hilton delivers customized mobile app solutions to meet
                      your business needs, ensuring cost-effective development
                      of hybrid applications by our expert team.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/mobile-app-development"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center webapp">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/webapp.webp"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/web-app-development">
                        Web Application Development
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                      Hilton accelerates business growth with our skilled team
                      of software architects, developers, testers, and project
                      managers, delivering high-quality services for market
                      differentiation.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/web-app-development"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img src="/assets/images/icons/cms.webp" alt="Features" />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/cms-development">
                        CMS development services
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                      Hilton specializes in expert CMS development for efficient
                      website content and data management. Our skilled
                      developers specialize in prominent platforms for seamless
                      solutions.
                    </p>
                  </div>
                </div> */}

                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center techsupport">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/itsupport.webp"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/technical-support">
                        Technical Support
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                      By outsourcing technical support services, you can focus
                      on business growth while experienced professionals handle
                      customer inquiries, saving time and resources on setting
                      up your own support center.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/technical-support"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="service-style1 layout2 text-center digitalmarketing">
                    <div className="service-bg background-image"> </div>{" "}
                    <div className="service-icon">
                      <img
                        src="/assets/images/icons/digitalmarketing.webp"
                        alt="Features"
                      />
                    </div>
                    <h3 className="service-title h5">
                      <ScrollToTopLink to="/digital-marketing">
                        Digital Marketing Service
                      </ScrollToTopLink>
                    </h3>
                    <p className="service-text">
                      Hilton's digital marketing agency provides comprehensive
                      solutions for business growth, including SEO, SEM, and
                      SMM, to drive leads and online traffic, covering all your
                      digital channels.
                    </p>
                    <div className="btn-wrap mt-40 mb-20">
                      <a
                        target="_self"
                        className="fl-button yellow-bg"
                        href="/digital-marketing"
                        tabIndex="0"
                        previewlistener="true"
                      >
                        <span className="fl-button-text">Know More</span>
                        <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        
                      </a>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="rs-services style8 bg14 pt-60 pb-60 md-pt-60 md-pb-60 hidden">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-5 pr-25 md-pr-15 md-mb-50">
              <div className="sec-title">
                <h2 className="title pb-22">What we offer</h2>
              </div>
              <div className="sec-title3">
                <p className="margin-0 pb-40">
                  Our comprehensive software development solutions deliver
                  robust and effective IT solutions customized to your business
                  needs, ensuring sustainable growth.
                </p>
              </div>
            </div>
            <div className="col-lg-7 pl-30 md-pl-15">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="flip-box-inner mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/full-stack.webp" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Development</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Empower your business with our cutting-edge
                              development solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Development</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Our passion lies in delivering robust software
                              solutions to clients, with a specialization in
                              enterprise-level systems that address business
                              challenges in today's ever-changing marketplace.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-box-inner sm-mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/computer.webp" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>IT Support</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Reliable IT support that keeps your business
                              running smoothly.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>IT Support</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Setting up and maintaining a support center can be
                              a time-consuming and expensive. By outsourcing
                              your technical support services, you can shift
                              your focus to business expansion instead of
                              managing customer inquiries.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-30 md-mt-0">
                  <div className="flip-box-inner mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img
                                src="/assets/images/online-shopping.webp"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Designing</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Unleash creativity with our exceptional design
                              solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Designing</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Our Web development services deliver visually
                              captivating and responsive designs that seamlessly
                              adapt to various mobile devices, including
                              desktops, laptops, and tablets.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-box-inner">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/mobile-app.webp" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Marketing</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Unlock your business's online potential with our
                              expert digital marketing solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Marketing</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Hilton is a reliable digital marketing company
                              providing comprehensive solutions for your brand's
                              digital requirements, including design and social
                              media strategy. Our knowledgeable team is driven
                              by a passion for continuous learning.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Partner Section Start --> */}
      <div className="rs-partner gray-bg pt-60 pb-60 service hidden">
        <div className="container">
          <div className="sec-title2 text-center md-left mb-40">
            <h2 className="title">Technologies we work with</h2>
          </div>
          <Slider {...clientslider}>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Android.webp" alt="" />
                  Android
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/AWS-1.webp" alt="" />
                  AWS
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img
                    src="/assets/images/technologies/Firebase-1.webp"
                    alt=""
                  />
                  Firebase
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img
                    src="/assets/images/technologies/Knockout.js.webp"
                    alt=""
                  />
                  Knockout.js
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Kotlin.webp" alt="" />
                  Kotlin
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Laravel.webp" alt="" />
                  Laravel
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Magento.webp" alt="" />
                  Magento
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/MongoDB.webp" alt="" />
                  MongoDB
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/MySQL.webp" alt="" />
                  MySQL
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img
                    src="/assets/images/technologies/node-js-logo.webp"
                    alt=""
                  />
                  Node.Js
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Shopify-1.webp" alt="" />
                  Shopify
                </a>{" "}
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Swift.webp" alt="" />
                  Swift
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Vue.js.webp" alt="" />
                  Vue.js
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                {" "}
                <a>
                  <img src="/assets/images/technologies/Yii.webp" alt="" />
                  Yii
                </a>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* <!-- Partner Section End --> */}

      <div
        id="rs-services"
        className="rs-services style2 pt-100 pb-60 md-pt-70 md-pb-60 hidden"
      >
        <div className="container">
          <div className="sec-title2 d-flex align-items-center mb-60 md-mb-40">
            <div className="first-half">
              <h2 className="title2 mb-0 md-pb-20">
                We are awesome team
                <br />
                for your business dream
              </h2>
            </div>
            <div className="last-half">
              <p className="desc mb-0 pl-20 md-pl-15">
                Our enduring partnerships with this e-Commerce, strategic, and
                technology allies fill us with pride and gratitude. Their
                creative ideas, unwavering support, and consulting proficiency
                are essential ingredients that enable us to create exceptional
                digital experiences. Our project flow is as follows!
              </p>
            </div>
          </div>
        </div>
        <div className="container awesome-team">
          <div className="process-effects-layer">
            <div className="row">
              <div className="col-lg-4 col-md-4 md-mb-30">
                <div className="rs-addon-number text-center">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/initialization.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 1 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Project initialization</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 md-mb-30">
                <div className="rs-addon-number text-center">
                  <div className="number-part">
                    <div className="number-image">
                      <img src="/assets/images/planning.webp" alt="Process" />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 2 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Project planning</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="rs-addon-number text-center">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/organization.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 3 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Project organization</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Testimonial Section End --> */}

      {/* <!-- Blog Start --> */}
      <div className="rs-blog style2 home5-blog-style bg11 pt-60 pb-60 md-pt-60 md-pb-60 hidden">
        <div className="container">
          <div className="row y-middle md-mb-30">
            <div className="col-lg-12 mb-20 md-mb-10 text-center">
              <div className="sec-title3 mb-10">
                <span className="sub-title paste-color">News Updates</span>
                <h2 className="title pb-20">
                  Read our latest updates & business tips & tricks
                </h2>
              </div>
            </div>
          </div>
          <RecentBlog />
        </div>
      </div>
      
      <div className={`app rs-blog style2 home5-blog-style yellow-bg pt-40 pb-40`} style={{ position: 'relative' }}>
        <div className="container-fluid">
          <div className="row y-middle">
            <div className="col-sm-9">
              <div className="sec-title3">
                <h2 className="title">
                Let's work together to bring your software ideas to life—contact us today!
                </h2>
              </div>
            </div>
            <div className="col-sm-3" style={{float:'right'}}>
              <div className="btn-wrap">
                <a
                  target="_self"
                  className="fl-button"
                  href="/blog"
                  tabIndex="0"
                  previewlistener="true"
                >
                  <span className="fl-button-text">Contact Us</span>
                  <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                  
                </a>
              </div>
            </div>
          </div>
          <RecentBlog />
      </div>
    </div>

      <Footer />
    </>
  );
};

export default HireADeveloper;
