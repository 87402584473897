import React, { useEffect, useState } from "react";
import Header from "./Header";
import CategoryList from "./CategoryList";
import RecentPosts from "./RecentPosts";
import BlogItem from "./BlogItem";
import Pagination from "./Pagination";
import Footer from "./Footer";
import axios from "axios";
import { API_ENDPOINT } from "../Api";
import AppLoader from "./AppLoader/index";

function BlogList() {
  const [recentPosts, setRecentPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const postsPerPage = 4;

  const handleCategoryClick = (categoryId) => {
    if (categoryId == null) {
      fetchPosts();
    } else {
      fetchCategory(categoryId);
    }
    setCurrentPage(1);
  };

  const fetchCategory = async (categoryId) => {
    setLoading(true);
    await axios
      .get(`${API_ENDPOINT}/posts?_embed&&categories=${categoryId}`)
      .then((response) => {
        setRecentPosts(response.data);
      })
      .catch((error) =>
        console.error("Error fetching posts for the category:", error)
      );
    setLoading(false);
  };

  const fetchPosts = async () => {
    setLoading(true);

    await axios
      .get(`${API_ENDPOINT}/posts?_embed`)
      .then((response) => {
        setRecentPosts(response.data);
      })
      .catch((error) => console.error("Error fetching recent posts:", error));
    setLoading(false);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = recentPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div>
      <Header />
      <div className="rs-breadcrumbs blog">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Blog</h1>
          </div>
        </div>
      </div>
      <div className="rs-inner-blog rs-blog style2 pt-60 pb-60 md-pt-60 md-pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="widget-area">
                <CategoryList onCategoryClick={handleCategoryClick} />
                <RecentPosts recentPosts={recentPosts} />
              </div>
            </div>
            <div className="col-lg-8 pr-35 md-pr-15 md-mt-50">
              {loading ? (
                <div className="blog-details">
                  <AppLoader />
                </div>
              ) : (
                <div className="row">
                  {currentPosts.map((post) => {
                    return (
                      <div key={post.id} className="col-lg-6 mb-50">
                        <BlogItem
                          key={post.id}
                          title={post.title.rendered}
                          date={post.date}
                          content={post.excerpt.rendered}
                          featured_media={
                            post._embedded["wp:featuredmedia"]["0"][
                              "source_url"
                            ]
                          }
                          slug={post.slug}
                        />
                      </div>
                    );
                  })}

                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={recentPosts.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogList;
