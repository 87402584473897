import Slider from "react-slick/lib/slider";
import { blogslider } from "./CarosuelsSettings";
import BlogItem from "./BlogItem";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_ENDPOINT } from "./../Api";

const RecentBlog = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const fetchCategory = async () => {
    await axios
      .get(`${API_ENDPOINT}/posts?_embed`)
      .then((response) => {
        setRecentPosts(response.data);
      })
      .catch((error) => console.error("Error fetching recent posts:", error));
  };

  useEffect(() => {
    fetchCategory();
  }, []);
  return (
    <Slider {...blogslider}>
      {recentPosts.map((post) => {
        return (
          <BlogItem
            key={post.id}
            title={post.title.rendered}
            date={post.date}
            content={post.excerpt.rendered}
            featured_media={
              post._embedded["wp:featuredmedia"]["0"]["source_url"]
            }
            slug={post.slug}
          />
        );
      })}
    </Slider>
  );
};

export default RecentBlog;
